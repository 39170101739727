"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const ng = window.angular;
const uuid_1 = require("uuid");
const action_1 = require("@src/app/services/action");
const defaultVersion = 4;
class UuidgeneratorCtrl {
    constructor($scope, $location, ConfigService, actionService) {
        this.$scope = $scope;
        this.$location = $location;
        this.ConfigService = ConfigService;
        this.actionService = actionService;
        this.allowedVersions = [1, 4];
        this.option = {
            version: this._getParam('version') || defaultVersion,
            amount: 1,
            uppercase: JSON.parse(localStorage.getItem('uppercase')) || false,
            braces: JSON.parse(localStorage.getItem('braces')) || false,
        };
        this.result = '';
    }
    $onInit() {
        let initial = false;
        this.$scope.$watch('$ctrl.option.version', (version) => {
            if (initial) {
                this._setSize(version);
            }
            initial = true;
            this.option.amount = 1;
            this.generate();
        });
        this.$scope.$watch('$ctrl.option.uppercase', (uppercase) => {
            localStorage.setItem('uppercase', uppercase.toString());
        });
        this.$scope.$watch('$ctrl.option.braces', (braces) => {
            localStorage.setItem('braces', braces.toString());
        });
    }
    newGame(version) {
        this.option.version = version;
    }
    generate() {
        let result = '';
        if (this.option.version == 4) {
            result = [...Array(this.option.amount)].map(() => {
                return this.option.braces ? `{${(0, uuid_1.v4)()}}` : (0, uuid_1.v4)();
            }).join('\n');
        }
        if (this.option.version == 1) {
            result = [...Array(this.option.amount)].map(() => {
                return this.option.braces ? `{${(0, uuid_1.v1)()}}` : (0, uuid_1.v1)();
            }).join('\n');
        }
        this.result = this.option.uppercase ? result.toUpperCase() : result;
    }
    copy() {
        this.actionService.copyFactory(document.getElementById('widget__field'), this.result);
    }
    download() {
        this.actionService.downloadFactory('uuidgenerator_zone', this.result);
    }
    _getParam(key) {
        const urlParams = new URLSearchParams(window.location.search);
        const value = Number(urlParams.get(key)) || defaultVersion;
        if (!isNaN(value) && (this.allowedVersions.indexOf(value) >= 0)) {
            return value;
        }
        else {
            urlParams.delete(key);
            window.location.search = urlParams.toString();
        }
        return null;
    }
    _setSize(version) {
        if (this.allowedVersions.indexOf(version) >= 0) {
            this.$location.search('version', version);
        }
    }
}
UuidgeneratorCtrl.$inject = ['$scope', '$location', 'ConfigService', 'ActionService'];
const appModule = ng.module('app');
appModule.service('ActionService', action_1.ActionService);
appModule.component('gameUuidgenerator', {
    transclude: true,
    template: require("./game.ng.html"),
    controller: UuidgeneratorCtrl,
    controllerAs: '$ctrl',
    bindings: {
        config: "<"
    }
});
appModule.config(['WsServiceProvider', (WsServiceProvider) => {
        WsServiceProvider.setPrefix('uuidgenerator/');
    }]);
